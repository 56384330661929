body{
  background: rgb(255, 255, 255);
}
.search {
  width: 40rem;
  margin: auto;
  margin-top: 2rem;
  text-align: left;
}
.search-box {
  border: 1px solid rgb(195, 203, 207);
  border-radius: 42px;
  font: 2rem Georgia, serif;
  width: 100%;
  padding: 0.5rem;
  box-shadow:0 0 5px rgb(195, 203, 207) inset;
  text-indent: 20px;
  padding-bottom: 1px;
}
.search-box:focus{
  width: 100%;
  outline: none;
}

ul.options {
  width: 40rem;
  margin: auto;
  text-align: left;
  padding: 0;
}

ul.options li {
  display: block;
  background: white;
  margin: 10px auto;
  padding: 10px;
  font: 2rem Georgia, serif;
  width: 100%;
  padding: 0.5rem;
  text-indent: 20px;
  padding-bottom: 1px;
  border-radius: 42px;
}
ul.options li:hover {
  cursor: pointer;
  background: whitesmoke;
}
